<template>
    <div class="card" id="tratamentoTransacaoRe-list">

        <div class="flex align-items-center">
           <i class="pi pi-history mr-1" style="font-size: 1.4rem"></i>
           <h3 style="margin:0px 5px;">Tratamento Transação RE</h3>
        </div>

        <hr />

        <Message v-for="msg of mensagens" :severity="msg.gravidade">{{ msg.descricao }}</Message>
        
        <DataTable 
            responsiveLayout="stack"
            v-model:selection="transacaoSelecionada"
            selectionMode="single"
            showGridlines
            :value="transacoes"
            :row-class="data => rowClass(data)"
            @sort="sort($event)">

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2" style="width: 20rem;">
                        <label>
                            <strong>Estabelecimento</strong>
                        </label>

                        <AutoComplete 
                            dropdown
                            placeholder="Selecione..." 
                            v-model="filtros.estabelecimento"
                            field="label"
                            id="estabelecimento"
                            class="w-full"
                            :suggestions="estabelecimentosFiltro"
                            :forceSelection="true"
                            @complete="buscarEstabelecimento"
                            @item-select="filtrar()"
                        />
                    </div>

                    <div class="field mr-2" style="width: 13rem;">
                        <label for="movimento">
                            <strong>Movimento</strong>
                        </label>

                        <AutoComplete 
                            dropdown
                            placeholder="Selecione..." 
                            v-model="filtros.tipo"
                            field="descricao"
                            id="movimento"
                            class="w-full"
                            :suggestions="tipoMovimentoReFiltro"
                            :forceSelection="true"
                            @complete="buscarTipoMovimentoRe"
                        />
                    </div>

                    <div class="field mr-2" style="width: 13rem;">
                        <label for="status">
                            <strong>Status</strong>
                        </label>

                        <AutoComplete 
                            dropdown
                            placeholder="Selecione..." 
                            v-model="filtros.status"
                            field="descricao"
                            id="status"
                            class="w-full"
                            :suggestions="statusMovimentoReFiltro"
                            :forceSelection="true"
                            @complete="buscarstatusMovimentoRe"
                        />
                    </div>

                    <div class="field mr-2">
                        <label>
                            <strong>Nº RE</strong>
                        </label>

                        <div class="flex align-items-center">
                            <div class="overflow-hidden" style="width:6rem;">
                                <InputNumber class="w-full" v-model="filtros.nrReInicio" />
                            </div>

                            <strong class="ml-2 mr-2">à</strong>
                            
                            <div class="overflow-hidden" style="width:6rem;">
                                <InputNumber class="w-full" v-model="filtros.nrReFim" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                            label="Filtrar"
                            icon="pi pi-filter"
                            class="p-button-outlined p-button-info"
                            @click="filtrar"
                            ></Button>
                            
                            <Button 
                            title="Limpar Filtro"
                            icon="pi pi-filter-slash"
                            class="p-button-outlined p-button-info"
                            style="margin-left: 1px;"
                            @click="limparfiltros()"
                            ></Button>
                        </div>
                    </div>
                    
                    <div class="field mr-2">
                        <label>&nbsp;</label>

                        <div class="flex align-items-center">
                            <Checkbox v-model="filtros.tempoProlongado" id="tempo-prolongado" :binary="true" @change="filtrar" />
                            <label for="tempo-prolongado" class="ml-2">Tempo prolongado</label>
                        </div>
                    </div>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="codEstabel" header="Estab." style="width:5rem;" sortable>
                <template #body="{ data, field }">
                    {{ data[field] }}
                    <i v-tooltip.top="'Atenção: Verifique a RE, pois está muito tempo no mesmo tipo de movimento.'" v-if="verificarSeEstaMuitoTempoComMesmoTipo(data)" class="pi pi-exclamation-triangle ml-2"  style="color: #c57c14; font-weight: bold; font-size: 1.2rem;"></i>
                </template>
            </Column>

            <Column field="idRe" header="ID RE" style="width:8rem;" sortable>
                <template #body="{ data, field }">
                    {{ formatDecimal(data[field], 0, 0) }}
                </template>
            </Column>

            <Column field="nrRe" header="Nº RE" style="width:8rem;" sortable>
                <template #body="{ data, field }">
                    {{ formatDecimal(data[field], 0, 0) }}
                </template>
            </Column>

            <Column field="descricaoMovimentoAtual" header="Movimento" style="width:12rem;" sortable />
            <Column field="descricaoStatusMovimentoAtual" header="Status" style="width:5rem;" sortable />

            <Column field="dataUltimoMovimento" header="Data Últ. Mov." style="width:12rem;" sortable class="centralizar-titulo-tabela">
                <template #body="{ data, field }">
                    {{ formatDate(data[field], 'DD/MM/YYYY HH:mm:SS') }}
                </template>
            </Column>
            <Column />
            <Column header="Ações" style="width:5rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <Button icon="pi pi-search"
                        title="Visualizar Movimentos da RE"
                        class="p-button-rounded p-button-info p-2 mr-2"
                        @click="abrirMovimentos(data.idRe, data.codEstabel)">
                    </Button>
                </template>
            </Column>
        </DataTable>
        <Paginator
            v-model:first="firstRow"
            :rows="qtdRegistro"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage"
        />
    </div>

    <Movimentos
        :idRe="idRe"
        :visivel="movimentoReDialog"
        :codigoEstabelecimento = "codigoEstabelecimento"
        @fechar="fecharMovimentos()"
    />

</template>

<script>
    import EstabelecimentoService from '../../service/EstabelecimentoService';
    import StorageService from '../../service/StorageService';
    import Formatacao from '../../utilities/Formatacao';
    import Paginator from 'primevue/paginator';
    import TratamentoTransacaoReService from '../../service/TratamentoTransacaoReService';
    import Movimentos from '../consulta/TratamentoTransacaoReMovimentos.vue';

    export default {
        name: 'TratamentoTransacaoRE_List',
        components: { Paginator, Movimentos },
        data() {
            return {
                totalRegistro: 0,
                qtdRegistro: 30,
                pagina: 0,
                firstRow: 0,
                ordenar:'id,desc',
                                
                transacoes: [],
                transacaoSelecionada: {},
                
                integration: {},
                
                idRe: null,
                movimentoReDialog:false,
                codigoEstabelecimento: null,

                estabelecimentoLista: [],
                estabelecimentosFiltro: [],

                filtros: {},
                tipoMovimentoReLista: [],
                tipoMovimentoReFiltro: [],

                statusMovimentoReLista: [],
                statusMovimentoReFiltro: [],

                estabelecimentoFiltroDinamico: StorageService.getEstabelecimento(),
                mensagens: []
            }
        },

        created() {
            const storange = StorageService.getControlePagina("TRATAR_TRANSACOES");
            if(!storange.default) this.filtros = storange.filtros;
            
            this.pagina = storange.numero;
            this.firstRow = storange.firstRow;
            this.qtdRegistro = storange.qtdRegistro;
            this.carregarDados();
            this.carregarInformacoes();
            this.carregarEstabelecimentos();
            this.carregarTiposMovimentoRe();
            this.carregarStatusMovimentoRe();
        },

        methods: {
            carregarInformacoes() {
                TratamentoTransacaoReService.informacoes()
                    .then(({ data }) => { this.mensagens = data; });
            },

            carregarEstapelecimmentoNoFiltroDinamico() {
                if(!this.filtros.estabelecimento || !this.filtros.estabelecimento.codigo) return;
                const filtroDinamico = {};
                if (this.filtros.estabelecimento["codigo"]==undefined) { return; }

                filtroDinamico["codigo"] = this.filtros.estabelecimento["codigo"];
                filtroDinamico["codigoRegional"] = this.filtros.estabelecimento["codigoRegional"];
                filtroDinamico["nomeFantasia"] = this.filtros.estabelecimento["nomeFantasia"];              
                filtroDinamico["razaoSocial"] = this.filtros.estabelecimento["razaoSocial"];
                StorageService.setEstabelecimento(filtroDinamico);
            },
            
            carregarTiposMovimentoRe() {
                TratamentoTransacaoReService.listarTipoMovimentoRe()
                    .then(({ data }) => { 
                        this.tipoMovimentoReLista = data; 
                        this.tipoMovimentoReLista.unshift({ descricao: "Todos" });
                    });
            },

            buscarTipoMovimentoRe(event) {
                const descricao = !event.query.trim().length ? null : event.query.toLowerCase();

                if (!!descricao) {
                    this.tipoMovimentoReFiltro = this.tipoMovimentoReLista.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(descricao);
                    });
                } 
                else {
                    this.tipoMovimentoReFiltro = [...this.tipoMovimentoReLista];
                }
                
            },

            carregarStatusMovimentoRe() {
                TratamentoTransacaoReService.listarStatusMovimentoRe()
                    .then(({ data }) => { 
                        this.statusMovimentoReLista = data;
                        this.statusMovimentoReLista.unshift({ descricao: "Todos" });
                    });
            },

            buscarstatusMovimentoRe(event) {
                const descricao = !event.query.trim().length ? null : event.query.toLowerCase();

                if (!!descricao) {
                    this.statusMovimentoReFiltro = this.statusMovimentoReLista.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(descricao);
                    });
                } 
                else {
                    this.statusMovimentoReFiltro = [...this.statusMovimentoReLista];
                }
                
            },
            
            limparfiltros(){
                this.pagina = 0;
                this.firstRow = 0;
                this.filtros = { page: 0, sort: 'id,desc', size: this.qtdRegistro };
                this.carregarDados();
                this.carregarEstapelecimmentoNoFiltroDinamico();
            },

            filtrar() {
                this.pagina = 0;
                this.firstRow = 0;
                this.carregarDados();
                this.carregarEstapelecimmentoNoFiltroDinamico();

            },

            getfiltros() {
                const params = {};
                params["page"] = this.pagina;
                params["size"] = this.qtdRegistro;
                params["sort"] = this.ordenar;
                params["tempoProlongado"] = this.filtros.tempoProlongado;

                if(!!this.filtros.estabelecimento) {
                    params["codigoEstab"] = this.filtros.estabelecimento.codigo;
                }
                else {
                    params["codigoEstab"] = undefined;
                }

                if(!!this.filtros.tipo) {
                    params["tipo"] = this.filtros.tipo.codigo;
                }
                else {
                    params["tipo"] = undefined;
                }

                if(!!this.filtros.status) {
                    params["status"] = this.filtros.status.codigo;
                }
                else {
                    params["status"] = undefined;
                }

                if(this.filtros.nrReInicio != null && this.filtros.nrReInicio != undefined 
                && this.filtros.nrReFim != null && this.filtros.nrReFim != undefined) {
                    params["nrReInicio"] = this.filtros.nrReInicio;
                    params["nrReFim"] = this.filtros.nrReFim;
                }
                else {
                    params["nrReInicio"] = undefined;
                    params["nrReFim"] = undefined;
                }
                
                StorageService.setControlePagina("TRATAR_TRANSACOES", this.pagina, this.firstRow, this.qtdRegistro, this.filtros);
                this.carregarEstapelecimmentoNoFiltroDinamico();
                return params;
            },

            carregarDados() {
                const filtros = this.getfiltros();
                TratamentoTransacaoReService.buscarPorPaginacao(filtros)
                    .then(({ data }) => {
                        this.transacoes = data.content;
                        
                        this.qtdRegistro = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    })
                    .catch(error => {
                        this.transacoes = [];
                        this.qtdRegistro = 0;
                        this.totalRegistro = 0;
                    });
            },

            sort(event) {
                const sortField = event.sortField;
                const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
                this.ordenar = `${ sortField },${sortOrder}`;
                this.carregarDados();   
            }, 
            
            onPage(event) {          
                this.pagina = event.page + 1;
                this.firstRow = event.rows * event.page;
                this.qtdRegistro = event.rows;                     
                this.carregarDados();
            },

            carregarEstabelecimentos() {
                EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(undefined)
                    .then(({ data }) => {    
                        const estabelecimentos = data.map(item => {
                            item["label"] = item.codigo + " - " + item.nomeFantasia;
                            return item;
                        });

                        estabelecimentos.unshift({label: "Todos"});
                        this.estabelecimentoLista = [...estabelecimentos];

                        if(!!this.estabelecimentoFiltroDinamico && Object.keys(this.estabelecimentoFiltroDinamico).length > 0) {
                            const estabelecimento = this.estabelecimentoLista.find((item) => item.codigo === this.estabelecimentoFiltroDinamico.codigo);
                            if(estabelecimento != undefined) this.filtros.estabelecimento = estabelecimento;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.estabelecimentoLista = [];
                    });
            },

            buscarEstabelecimento(event) {
                const label = !event.query.trim().length ? null : event.query.toLowerCase();

                if (!!label) {
                    this.estabelecimentosFiltro = this.estabelecimentoLista.filter((item) => {
                        return item.label.toLowerCase().startsWith(label);
                    });
                } 
                else {
                    this.estabelecimentosFiltro = [...this.estabelecimentoLista];
                }

            },

            formatDate(data, format) {
                return Formatacao.formatDateCustom(data, format);
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },
            
            abrirMovimentos(idRe, codigoEstabelecimento){
                this.idRe = idRe;
                this.movimentoReDialog = true;
                this.codigoEstabelecimento = codigoEstabelecimento;
            },

            fecharMovimentos(){
                this.idRe = null;
                this.movimentoReDialog = false;
                this.codigoEstabelecimento = null;
            },
            
            rowClass(data) { return data.statusMovimentoAtual === 'FALHA' ? "linha-falha-re" : ""; },

            verificarSeEstaMuitoTempoComMesmoTipo(data) {
                if(data.movimentoAtual === 'ABERTO') return false;
                const qtdMinutos = Formatacao.obterDiferencaEmMinutos(data.dataUltimoMovimento, new Date());
                return qtdMinutos > 60;
            }
        }
    }
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .linha-falha-re {
        background-color: #ffcdd2 !important;
        color: #be1c1c !important;
    }
</style>
